.light {
  color-scheme: light;
  /* Brand */
  --brand-primary: #00a16f;
  --brand-primary-hover: #03724f;
  --brand-secondary: #e9f6f2;

  /* Text */
  --text-primary: #202022;
  --text-secondary: #505556;
  --text-tertiary: #acaeb0;
  --text-muted: #cecece;
  --text-placeholder: #cecece;
  --text-white: #ffffff;
  --text-link: #4540db;

  /* Background */
  --background-primary: #ffffff;
  --background-secondary: #f9f9fa;
  --background-tertiary: #fcfcfc;
  --background-muted: #e3e8ea;
  --background-overlay: #0000001a;
  --background-thumb-overlay: #00000099;
  --background-linear-gradient: linear-gradient(180deg, #f9f9fa 0%, #f9f9fa 53.62%, rgba(249, 249, 250, 0) 100%);

  /* Border */
  --border-primary: #dfdfdf;
  --border-secondary: #acaeb0;

  /* Accent */
  --accent-primary: #51b2e8;
  --accent-secondary: #735cd2;
  --accent-success: #5fbb25;
  --accent-warning: #ff6347;
  --accent-caution: #f5a623;
  --accent-error: #db0b0b;
  --accent-highlight: #f8d200;

  /* Neutral */
  --neutral-primary: #d4dade;
  --neutral-secondary: #f1f4f4;

  /* Box Shadow */
  --shadow-card: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
  --shadow-card-hover: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  --shadow-dropdown: 0px 4px 8px 0px rgba(0, 0, 0, 0.1), 0px 8px 24px 0px rgba(0, 0, 0, 0.1);
  --shadow-input-focus: 1px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(233, 246, 242, 1);
  --shadow-modal: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  --shadow-button: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);

  /* desktop app  */
  --background-hover: rgba(0, 0, 0, 0.06);
  --border-color: rgba(0, 0, 0, 0.12);
  --app-background-color: #e0e1df;
  --tabs-background: rgba(0, 0, 0, 0.06);
  --tabs-hover-background: rgba(0, 0, 0, 0.12);
  --icon-hover-background: rgba(0, 0, 0, 0.08);

  /* icon hover brightness */
  --brightness-value: 0.7;
  --brightness-value2: 0.8;

  /* for media resolution 2dpx border color */
  @media (min-resolution: 2dppx) {
    --border-primary: #cecece;
  }
}

.dark {
  color-scheme: dark;
  /* Brand */
  --brand-primary: #00a16f;
  --brand-primary-hover: #03724f;
  --brand-secondary: #2a2d2e;

  /* Text */
  --text-primary: #ffffff;
  --text-secondary: #acaeb0;
  --text-tertiary: #505556;
  --text-muted: #2e2e30;
  --text-placeholder: #505556;
  --text-white: #ffffff;
  --text-link: #7282ff;

  /* Background */
  --background-primary: #202022;
  --background-secondary: #0f1213;
  --background-tertiary: #252527;
  --background-muted: #505556;
  --background-overlay: #000000b7;
  --background-thumb-overlay: #00000099;
  --background-linear-gradient: linear-gradient(180deg, #0f1213 0%, #0f1213 53.62%, rgba(249, 249, 250, 0) 100%);

  /* Border */
  --border-primary: #2e2e30;
  --border-secondary: #505556;

  /* Accent */
  --accent-primary: #51b2e8;
  --accent-secondary: #735cd2;
  --accent-success: #5fbb25;
  --accent-warning: #ff6347;
  --accent-caution: #f5a623;
  --accent-error: #ef6262;
  --accent-highlight: #f8d200;

  /* Neutral */
  --neutral-primary: #d4dade;
  --neutral-secondary: #2a2d2e;

  /* Box Shadow */
  --shadow-card: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
  --shadow-card-hover: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  --shadow-dropdown: 0px 4px 8px 0px rgba(0, 0, 0, 0.1), 0px 8px 24px 0px rgba(0, 0, 0, 0.1);
  --shadow-input-focus: 1px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(233, 246, 242, 1);
  --shadow-modal: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  --shadow-button: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);

  /* icon hover brightness */
  --brightness-value: 1.1;
  --brightness-value2: 1.1;

  /* desktop app  */
  /* use background-hover: rgba(0, 0, 0, 0.2); */
  --background-hover: rgba(0, 0, 0, 0.2);
  --border-color: rgba(60, 65, 66, 1);
  --app-background-color: #212121;
  --tabs-background: rgba(255, 255, 255, 0.06);
  --tabs-hover-background: rgba(255, 255, 255, 0.12);
  --icon-hover-background: rgba(255, 255, 255, 0.08);
}

@media (max-width: 449px) {
  body {
    position: fixed;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#rteInline .content-wrapper {
  width: auto;
  margin: 0;
  overflow: auto;
}

#inlineRTE-value {
  display: none;
}

#Inline_Quick_Popup_0 {
  position: absolute;
}

#inlineRTErte-view:focus-visible {
  outline: 0;
}

#inlineRTE_quick_Alignments-popup {
  position: absolute;
}

#inlineRTE_quick_Formats-popup {
  position: absolute;
}

#Link_Quick_Popup_1 {
  position: absolute;
}

#inlineRTE_rte-edit-view_popup {
  position: absolute;
  bottom: 0;
  height: fit-content;
}

#inlineRTE_rte-edit-view_popup > div {
  position: absolute;
  bottom: 0;
}

.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-content {
  grid-template-columns: repeat(auto-fill, 24px) !important;
  gap: 2px !important;
}

.EmojiPickerReact button.epr-emoji {
  max-height: 26px !important;
  max-width: 26px !important;
  height: 26px !important;
  width: 26px !important;
}

.EmojiPickerReact button.epr-emoji .epr-emoji-img {
  max-height: 26px !important;
  max-width: 26px !important;
  min-height: 26px !important;
  min-width: 26px !important;
  padding: 4px !important;
}

.EmojiPickerReact .epr-search-container button.epr-btn-clear-search {
  display: none !important;
}

.EmojiPickerReact .epr-header .epr-header-overlay {
  padding: 10px 10px 0 !important;
}

.epr_x558go:focus {
  border: unset;
}

.epr_-8ygbw8 {
  padding: 10px 10px 0 !important;
}

.EmojiPickerReact .epr-preview {
  display: none !important;
}

.EmojiPickerReact .epr-search-container .epr-icn-search {
  display: none !important;
}

.EmojiPickerReact .epr-category-nav > button.epr-cat-btn:focus:before {
  border: none !important;
}

.EmojiPickerReact .epr-skin-tones {
  padding: 0 !important;
}

.EmojiPickerReact .epr-category-nav {
  padding: 6px 10px 0px !important;
}

.EmojiPickerReact button.epr-cat-btn.epr-icn-smileys_people {
  background-position-x: -3px !important;
}

.EmojiPickerReact button.epr-cat-btn.epr-icn-animals_nature {
  background-position-x: -32px !important;
}

.EmojiPickerReact button.epr-cat-btn.epr-icn-food_drink {
  background-position-x: -61px !important;
}

.EmojiPickerReact:not(.epr-search-active) .epr-category-nav > button.epr-cat-btn.epr-active,
.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  font-family: 'Inter' !important;
  height: auto !important;
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  color: var(--text-secondary) !important;
}

.EmojiPickerReact .epr-body::-webkit-scrollbar {
  width: 6px !important;
  margin-bottom: 4px !important;
}

.EmojiPickerReact .epr-body::-webkit-scrollbar-thumb {
  background-color: var(--background-muted) !important;
  border-radius: 7px !important;
  /* height: 30px !important; */
  min-height: 20px !important;
}

.epr-body {
  padding-right: 3px !important;
  margin-bottom: 5px !important;
  margin-right: 3px !important;
}

.highcharts-point.highcharts-color-0 {
  stroke: none !important;
}

.highcharts-point.highcharts-color-9 {
  stroke: none !important;
}

.highcharts-point.highcharts-color-3 {
  stroke: none !important;
}

.highcharts-point.highcharts-color-7 {
  stroke: none !important;
}

.highcharts-point.highcharts-color-8 {
  stroke: none !important;
}

.highcharts-point.highcharts-color-1 {
  stroke: none !important;
}

.highcharts-point.highcharts-color-6 {
  stroke: none !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  height: 30px;
  padding-top: 6px;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
}

.ant-picker-dropdown .ant-picker-panel-container {
  background-color: var(--background-primary);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner.ant-picker-time-panel-cell-inner {
  background-color: var(--neutral-secondary);
}

.ant-picker-now-btn {
  line-height: 20px;
  font-weight: 400;
  font-size: 13px;
  color: var(--text-primary);
}

.ant-btn {
  line-height: 16px;
  font-weight: 500;
  font-size: 12px;
  box-shadow: none;
}

.ant-btn.ant-btn-sm {
  padding: 4px 16px;
  border-radius: 36px;
  height: unset;
}

.ant-picker-dropdown .ant-picker-ranges {
  margin: 0;
  padding: 12px 10px 7px;
  line-height: 22px;
  gap: 20px;
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok {
  margin-inline-start: 0;
}

.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell {
  margin-inline: 0;
}

.ant-picker-dropdown .ant-picker-time-panel-column > li {
  margin: 0 6px !important;
}

.ant-picker-dropdown .ant-picker-time-panel {
  width: 122px;
  padding-top: 0 !important;
}

.ant-time-picker-panel.ant-picker-dropdown ul::-webkit-scrollbar-thumb {
  width: 6px !important;
  height: 14px !important;
  border-radius: 3px !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column {
  margin: 0;
  padding-top: 8px;
  scrollbar-width: unset;
  scrollbar-color: unset;
}

.ant-picker-dropdown .ant-picker-footer {
  border-top: 0.5px solid var(--border-primary);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inned {
  background-color: var(--neutral-secondary);
}

.ant-picker-time-panel-column {
  &:first-child {
    border-right: 0.5px solid var(--border-primary);
  }
}

.ant-picker-dropdown .ant-picker-time-panel .ant-picker-content {
  height: 214px;
}

.ant-picker-dropdown .ant-picker-time-panel-column::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.ant-picker-dropdown .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  border-radius: 3px;
  height: 14px;
  background-color: var(--background-muted);
}

:where(.css-dev-only-do-not-override-4rue6m).ant-picker-dropdown .ant-picker-time-panel-column::after {
  height: 4px !important;
}

.ant-select-dropdown {
  border: 0.5px solid var(--border-primary) !important;
  background-color: var(--background-primary);
  color: var(--text-primary);
}

.e-popup.e-popup-open {
  display: none;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-color-picker-trigger .ant-color-picker-color-block,
:where(.css-kghr11).ant-color-picker-trigger .ant-color-picker-color-block {
  box-shadow: none;
  background-image: none;
  width: 64px;
  height: 64px;
}

.ant-color-picker-trigger,
:where(.css-kghr11).ant-color-picker-trigger {
  min-width: none;
  height: none;
  border: 0;
  padding: 0;
}

:where(.css-dev-only-do-not-override-1i67cov).ant-select-single {
  height: 36px;

  @media (max-width: 449px) {
    height: 44px;
  }
}

.ant-image-preview-close {
  background: unset;
  padding: 0;

  :hover {
    background: unset;
  }
}

.ant-image-preview-close:hover {
  background: unset;
}

.custom-dropdown {
  cursor: pointer;
}

/* Basic editor styles */

.tiptap {
  height: inherit;
  overflow: auto;
  outline: none !important;
  position: relative;
}

.tiptap > :first-child,
.tiptap > div > :first-child {
  margin-top: 5px;
}
.tiptap > div :first-child,
.tiptap > div > div > h1:first-child {
  margin-top: 0px !important;
}

.tiptap > :last-child,
.tiptap > div > :last-child {
  margin-bottom: 0;
}

ul[data-type='taskList'] {
  list-style: none;
  padding: 0 !important;
  margin-right: 1.25rem !important;
}

ul[data-type='taskList'] li {
  display: flex;
  align-items: center;
  gap: 8px;
}

ul[data-type='taskList'] li input[type='checkbox'] {
  margin: 0;
}

.tiptap ul,
.tiptap ol {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 8px 0 0 8px;
  padding: 0 1rem;
}

.tiptap ul li p,
.tiptap ol li p {
  margin-top: 0;
}

/* applied for bullet not visible in tiptap. */
.tiptap li {
  color: var(--text-primary);
  &::marker {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }
}

.tiptap p {
  color: var(--text-primary);
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 8px;
  text-wrap: pretty;
  margin-bottom: 0;
  @media (max-width: 449px) {
    font-size: 15px;
    line-height: 22px;
  }
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  margin-bottom: 0 !important;
  text-wrap: pretty;
  color: var(--text-primary);
}

.tiptap h4 + p:has(a) {
  margin-top: 6px !important;
}

.tiptap h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 24px;
}

.tiptap h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 18px;
}

.tiptap h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 12px;
}

.tiptap h4,
.tiptap h5,
.tiptap h6 {
  /* Code and preformatted text styles */
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 8px;
}

.tiptap code {
  color: var(--text-primary);
  padding: 0px 4px;
  background: var(--background-tertiary);
  border-radius: 4px;
  border: 0.5px solid var(--border-primary);
}

.tiptap pre {
  background: var(--background-secondary);
  border: 0.5px solid var(--border-primary);
  border-radius: 0.5rem;
  color: var(--text-primary);
  font-family: 'JetBrainsMono', monospace;
  margin: 0px;
  padding: 0.75rem 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: auto;
  display: grid;
  margin-top: 12px;
}
.tiptap pre:hover {
  .copy-icon {
    display: flex;
  }
}

.tiptap pre code {
  background: none;
  color: inherit;
  border: none;
  font-size: 0.8rem;
  padding: 0;
  white-space: pre !important;
  display: block;
  overflow-x: auto;
}

.button-div {
  display: none;
}

pre:hover {
  .button-div {
    display: flex;
    align-items: center;
    gap: 2px;
    position: absolute;
    right: 20px;
    border-radius: 3px;
    cursor: pointer;
    background-color: var(--background-secondary);

    .icon {
      stroke: var(--text-secondary);
    }
  }
}

.language-dropdown {
  cursor: pointer;
  border: none;
  appearance: none;
  color: var(--text-secondary);
  background: none;

  &:hover {
    color: var(--text-primary);
  }

  ::-webkit-scrollbar {
    display: none;
  }

  &:focus-visible {
    outline: none;
  }
}

.code-block-header {
  position: absolute;
  right: 16px;
}

.code-block-header:hover .button-div:hover {
  color: var(--text-primary);
}

.code-block-header:hover .button-div .icon {
  stroke: var(--text-primary);
}

.tiptap blockquote {
  margin: 8px 0 0;
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 3px;
    background: var(--text-placeholder);
    border-radius: 8px;
    margin-left: 8px;
  }
}

.tiptap hr {
  border: none;
  margin: 16px 0 4px;
  width: 100%;
  height: calc(2px);
  background: var(--border-primary);
  @media (max-width: 449px) {
    height: calc(0.5px);
  }
}

.tiptap a {
  color: var(--text-link);
  text-decoration: none;
  word-break: break-word;
}

.tiptap a:hover {
  text-decoration: underline;
}

/* mention styles */
.tiptap .mention {
  border-radius: 4px;
  box-decoration-break: clone !important;
  color: var(--brand-primary) !important;
  padding: 0 2px;
  background-color: color-mix(in srgb, var(--brand-primary) 10%, transparent);
}

.tippy-box[data-theme~='teamcamp'] {
  background-color: transparent;
}

.tippy-box[data-theme~='teamcamp'] .tippy-content {
  padding: 0;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: 1px;
  overflow: auto;
  padding: 4px;
  max-width: 170px;
  min-width: 145px;
  position: relative;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  box-shadow: var(--shadow-dropdown);
}

.dropdown-menu button {
  align-items: center;
  background-color: transparent;
  display: flex;
  gap: 8px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 4px 6px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  color: var(--text-primary);
}

.dropdown-menu button:hover,
.dropdown-menu button:hover.is-selected {
  background-color: var(--neutral-secondary);
}

.dropdown-menu button.is-selected {
  background-color: var(--neutral-secondary);
}

.tiptap img {
  display: block;
  height: auto;
  max-width: 300px;
  margin-top: 12px;
}

.tiptap img.ProseMirror-selectednode {
  outline: 3px solid var(--brand-primary);
}

.tiptap p.is-editor-empty:first-child::before {
  color: var(--text-secondary);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.bubble-menu {
  background-color: var(--background-primary);
  padding: 8px 12px;
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 36px;
  display: flex;
  gap: 8px;
  position: relative;

  .heading-dropdown {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  button {
    border: none;
    background-color: unset;
    margin: 0;
    padding: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 15px;

    &:hover {
      background-color: var(--neutral-secondary);
    }

    svg {
      stroke: var(--text-primary);
      fill: var(--text-primary);
    }
  }
}

.link-menu {
  padding: 5px 8px 5px 4px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 36px;
  display: flex;
  gap: 8px;
  align-items: center;

  input {
    min-width: 208px;
    max-width: 208px;
    width: 100%;
    border: none;
    outline: none;
    background-color: unset;
    padding: 5px 12px;
    color: var(--text-primary);
    font-size: 13px;
    font-weight: 400px;
    line-height: 20px;

    ::placeholder {
      color: var(--text-secondary);
    }

    :focus {
      outline: unset;
    }
  }

  .icon-div {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .line-div {
    height: 18px;
    border-left: 0.5px solid var(--border-primary);
  }
}

.heading-dropdown-menu {
  padding: 4px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 8px;
  width: auto;
  min-width: 116px;

  button {
    padding: 4px 6px;
    background: none;
    border: none;
    text-align: left;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-primary);
    border-radius: 4px;

    &:hover {
      background-color: var(--neutral-secondary);
    }
  }
}

.heading-dropdown-menu.bottom {
  top: calc(100% - 6px);
  left: 0;
}

.heading-dropdown-menu.top {
  bottom: calc(100% - 6px);
  left: 0;
}

/* added style for color picker popup */
.ant-popover .ant-popover-inner {
  background-color: var(--background-primary);
  box-shadow: var(--shadow-dropdown);
  color: var(--text-primary);
}

.ant-popover .ant-popover-arrow:before {
  background-color: var(--background-primary);
  box-shadow: var(--shadow-dropdown);
}

.ant-input-outlined {
  background-color: var(--background-primary);
  color: var(--text-primary);
}

.ant-select-single .ant-select-selector {
  color: var(--text-primary) !important;
}

.ant-select-single.ant-select-sm.ant-select-open .ant-select-selection-item {
  color: var(--text-primary);
}

.ant-input-outlined:hover {
  border-color: var(--border-primary);
  background-color: unset;
  color: var(--text-primary);
}

[class^='ant-input'],
[class*=' ant-input'] {
  background-color: var(--background-primary);
  color: var(--text-primary);
  overflow: hidden;
  border-color: var(--border-primary);

  :focus {
    background-color: unset;
  }
}

.ant-input-affix-wrapper > input.ant-input,
.ant-input-affix-wrapper > textarea.ant-input {
  background: unset;
  --antd-arrow-background-color: unset;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  box-shadow: unset;
  background-color: unset;
}

.ant-input-number .ant-input-number-input {
  color: var(--text-primary);
}

.ant-input-number-outlined .ant-input-number-handler-wrap {
  background-color: var(--background-primary);
}

.ant-input-number .ant-input-number-handler-up-inner,
.ant-input-number .ant-input-number-handler-down-inner {
  color: var(--text-primary);
}

.ant-select-dropdown .ant-select-item {
  color: var(--text-primary);
}

.ant-select .ant-select-arrow {
  color: var(--text-primary);
}

.ant-color-picker
  .ant-color-picker-inner
  .ant-color-picker-input-container
  .ant-color-picker-input
  .ant-color-picker-hex-input.ant-input-affix-wrapper
  .ant-input-prefix {
  color: var(--text-primary);
  border: unset;
}

.ant-input-number-outlined:hover {
  border-color: unset;
  background-color: unset;
}

.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--neutral-secondary);
  color: var(--text-primary);
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--neutral-secondary);
  color: var(--text-primary);
}

.drag-header {
  display: none;
}

.ant-message {
  z-index: 99999;
}

/* Table CSS */
/* .tiptap :first-child {
  margin-top: 0;
} */

.tiptap table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 8px;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
}

.tiptap tbody {
  position: relative;
}
.tiptap.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.tiptap table td,
.tiptap table th {
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
}
.tiptap table td {
  background-color: var(--background-primary);
}
.tiptap table th {
  background-color: var(--neutral-secondary);
  font-weight: bold;
  text-align: left;
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }
}

.tiptap .tableWrapper {
  margin: 8px 0;
  overflow-x: auto;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  overflow: hidden;
}

.tiptap .tableWrapper th,
.tiptap .tableWrapper td {
  border-bottom: 0.5px solid var(--border-primary);
  border-right: 0.5px solid var(--border-primary);
}
.tiptap .tableWrapper tbody tr:last-child th {
  border-bottom: none;
}

/* Remove bottom border for last row */
.tiptap .tableWrapper tbody tr:last-child td {
  border-bottom: none;
  &.selectedCell {
    border-bottom: 0.5px solid var(--accent-secondary);
  }
}

.tiptap .tableWrapper tbody tr td:last-child {
  border-right: none;
  &.selectedCell {
    border-right: 0.5px solid var(--accent-secondary);
  }
}

.tiptap .tableWrapper tbody tr th:last-child {
  border-right: none;
  &.selectedCell {
    border-right: 0.5px solid var(--accent-secondary);
  }
}

/* Selected Cell Styling */
.tiptap table .selectedCell {
  border: 0.5px solid var(--accent-secondary);
  position: relative;
}

/* Border Radius Fix */
.tiptap table tr:first-child .selectedCell:first-child {
  border-top-left-radius: 8px;
}
.tiptap table tr:first-child .selectedCell:last-child {
  border-top-right-radius: 8px;
}
.tiptap table tr:last-child .selectedCell:first-child {
  border-bottom-left-radius: 8px;
  border-bottom: 0.5px solid var(--accent-secondary);
}
.tiptap table tr:last-child .selectedCell:last-child {
  border-bottom-right-radius: 8px;
}

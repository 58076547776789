body {
  &.react-native {
    &.ipad {
      position: fixed;
      width: 100%;
      div {
        &.tabsbar-section {
          display: flex;
          background-color: var(--app-background-color);
        }
        &.back-forward-button {
          padding: 6px 8px 0 11px;
          display: flex;
        }
        &.ipad-header {
          max-width: max-content;
        }
        &.navigate-area {
          display: flex;
        }
        &.logo-icon {
          display: none;
        }
        &.back-forward-button.report-page {
          max-width: 240px;
        }
        .report-page {
          margin-left: 0;
        }
        .settings-page {
          margin-left: 0;
        }
        &.app-header {
          background-color: var(--app-background-color);
        }
        &.tabclose-icon {
          display: flex;
        }
        &.main-section {
          background-color: var(--app-background-color);
          height: calc(100dvh - 32px);
        }
        &.right-content {
          margin: 6px 6px 6px 0;
          border-radius: 8px;
          border: 0.5px solid var(--border-primary);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
          z-index: 1;
        }
        &.navbar {
          background-color: var(--app-background-color);
        }
        &.navbar-bottom {
          background-color: var(--app-background-color);
        }
        &.messages-group {
          height: calc(100dvh - 46px);
        }
        &.report-navbar {
          background-color: var(--app-background-color);
        }
        &.report-title {
          background-color: var(--app-background-color);
          border-top: 0.5px solid var(--border-color);
        }
        &.first-item {
          border-top: none;
        }
        &.report-submenu {
          border-top: 0.5px solid var(--border-color);
        }
        &.report-submenu:hover,
        &.back-icon:hover {
          background-color: var(--background-hover);
        }
        &.active-report-submenu {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.5) 100%);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
          backdrop-filter: blur(50px);
        }
        &.setting-navbar {
          background: var(--app-background-color);
        }
        &.menu-name:hover {
          background: var(--background-hover);
        }
        &.project-name:hover,
        &.organization-name:hover {
          background: var(--background-hover);
        }
        &.active-project-name {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.5) 100%);
          backdrop-filter: blur(50px);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
          border: 1px solid transparent;
        }
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  body {
    &.react-native {
      &.ipad {
        div {
          &.right-content {
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
          }
          &.active-report-submenu,
          &.back-icon:hover {
            background: rgba(80, 85, 86, 0.4);
          }
          &.active-project-name {
            background: rgba(80, 85, 86, 0.4);
          }
        }
      }
    }
  }
}

body {
  &.electron {
    &.windows {
      div {
        &.tabsbar-section {
          padding: 6px 8px 0px 0px;
          background-color: var(--app-background-color);
          &.report-page {
            margin-left: 0;
          }
          &.settings-page {
            margin-left: 0;
          }
        }
        &.app-header {
          background-color: var(--app-background-color);
        }
        &.back-forward-button {
          padding-left: 6px;
          background-color: var(--app-background-color);
          max-width: 188px;
          display: flex;
          &.report-page {
            max-width: 238px;
          }
        }
        &.navigate-area {
          display: flex;
        }
        &.expandclose-section {
          display: flex;
        }
        &.main-section {
          height: calc(100dvh - 32px);
          background-color: var(--app-background-color);
        }
        &.navbar {
          background-color: var(--app-background-color);
          height: calc(100dvh - 48px);
          margin-top: 12px;
        }
        &.right-content {
          border-radius: 6px;
          z-index: 1;
        }
        &.windowheader {
          display: flex;
          background-color: var(--app-background-color);
        }
        &.messages-group {
          height: calc(100dvh - 45px);
        }
        &.project-message-detail {
          height: calc(100dvh - 88px);
        }
        &.windows-onboarding-flow {
          height: calc(100dvh - 36px);
        }
        &.report-navbar {
          background-color: transparent;
        }
        &.timer-navbar {
          margin-top: 12px;
        }
        &.timer {
          background-color: var(--app-background-color);
        }
      }
    }
    &.mac {
      div {
        &.back-forward-button {
          display: flex;
          &.report-page {
            display: none;
          }
          &.settings-page {
            display: none;
          }
        }
        &.main-section {
          height: calc(100dvh - 32px);
        }
        &.navbar {
          background-color: transparent;
          height: calc(100dvh - 32px);
        }
        &.messages-group {
          height: calc(100dvh - 46px);
        }
        &.project-message-detail {
          height: calc(100dvh - 95px);
        }
        &.report-navbar {
          background-color: transparent;
          margin-top: 12px;
        }
      }
    }
    div {
      &.tabsbar-section {
        display: flex;
      }
      &.project-overview {
        height: calc(100dvh - 95px);
      }
      &.timer-navbar {
        background-color: transparent;
        height: calc(100dvh - 132px);
        margin-top: 0;
      }
      &.navbar-bottom {
        background-color: transparent;
      }
      &.right-content {
        margin: 6px 6px 6px 0;
        border-radius: 8px;
        border: 0.5px solid var(--border-primary);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      }
      &.drag-header {
        display: block;
        -webkit-app-region: drag;
      }
      &.project {
        color: var(--text-primary);
      }
      &.project-name:hover,
      &.organization-name:hover {
        background: rgba(0, 0, 0, 0.06);
      }
      &.active-project-name {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.5) 100%);
        backdrop-filter: blur(50px);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
        border: 1px solid transparent;
      }
      &.menu-name:hover {
        background: rgba(0, 0, 0, 0.06);
      }
      &.count-number {
        color: var(--text-primary);
      }
      &.timer {
        background-color: transparent;
      }
      // & apply to not working css for white-board
      .white-board {
        height: calc(100dvh - 97px) !important;
      }
      &.setting-navbar {
        background: transparent;
      }
      &.report-title {
        background-color: transparent;
        border-top: 0.5px solid var(--border-color);
      }
      &.first-item {
        border-top: none;
      }
      &.report-submenu {
        border-top: 0.5px solid var(--border-color);
      }
      &.report-submenu:hover,
      &.back-icon:hover {
        background-color: var(--background-hover);
      }
      &.active-report-submenu {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.5) 100%);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(50px);
      }
      &.back-button {
        padding: 6px 20px;
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  body {
    &.electron {
      &.windows {
        div {
          &.box:hover {
            background: rgba(80, 85, 86, 0.4);
          }
        }
      }
      div {
        &.right-content {
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        }
        &.menu-name:hover {
          background: var(--background-tertiary);
        }
        &.project-name:hover,
        &.organization-name:hover {
          background: var(--background-tertiary);
        }
        &.active-project-name {
          background: rgba(80, 85, 86, 0.4);
        }
        &.active-report-submenu,
        &.back-icon:hover {
          background: rgba(80, 85, 86, 0.4);
        }
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.ant-select-item-option-state {
  height: 16px;
}

.ant-select-dropdown .ant-select-item-option {
  align-items: center;
}

/* .rc-virtual-list-holder-inner {
  gap: 8px;
} */
.selectpopup .rc-virtual-list-holder-inner {
  gap: 0;
}

.rc-virtual-list-scrollbar-thumb {
  background: #acaeb0 !important;
  width: 6px !important;
  margin-bottom: 4px !important;
  border-radius: 7px !important;
  min-height: 20px !important;
}

.ant-select-dropdown .ant-select-item {
  padding: 7px 8px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.highcharts-legend-box {
  display: none;
}

.highcharts-credits {
  display: none;
}

.highcharts-no-tooltip {
  display: none !important;
}

.highcharts-title {
  display: none;
}

.highcharts-root {
  font-family: 'Inter, sans-serif' !important;
}

.highcharts-axis-line {
  display: none;
}

.highcharts-tick {
  display: none;
}

/* .highcharts-label .highcharts-tooltip .highcharts-color-undefined {
  transform: translate(867px, 214px) !important;
} */
/* .ant-tooltip .ant-tooltip-inner {
  width: 300px;
  font-family: 'Inter';
  min-height: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 8px;
  border-radius: 2px;
} */
@media (max-width: 449px) {
  /* .rc-virtual-list-holder-inner {
    gap: 8px;
  } */
  /* .rc-virtual-list-holder {
    max-height: 282px !important;
  } */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: unset;
}

.fOAzsT {
  padding: 10px 16px !important;
  background-color: #fff !important;
  border: 1px solid #e3e8ea !important;
  border-radius: 8px !important;
  margin: 0px !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
}
